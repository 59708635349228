import Tooltip from 'libs/tool-tip/Tooltip'

const Tags = ({ data, remainingCount, remaining, color, bgColor }: any) => {
  console.log('remainingShifts', remaining)
  return data?.length > 0 ? (
    <div className="flex flex-wrap">
      {data?.map((name: any, index: any) => (
        <span
          key={index}
          className={`m-1 rounded-[28px]  px-2 py-0.5`}
          style={{ color: color, backgroundColor: bgColor }}
        >
          {name}
        </span>
      ))}
      {remaining?.length > 0 && (
        <Tooltip
          content={
            remaining?.length > 0 ? (
              remaining?.map((name: any, index: number) => (
                <div key={index}>
                  <span>• </span>
                  <span>{name}</span>
                </div>
              ))
            ) : (
              <p>No name available</p>
            )
          }
          position="left"
        >
          <span
            className={`m-1 cursor-pointer rounded-[28px] px-2 py-0.5`}
            style={{ color: color, backgroundColor: bgColor }}
          >
            +{remainingCount}
          </span>
        </Tooltip>
      )}
    </div>
  ) : (
    <div>-</div>
  )
}

export default Tags
