import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Field from 'libs/field'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import { useGetMaintenanceRequestsQuery } from 'pages/maintenance/Maintenance-request/maintenance-request/maintenance-request'
import { useGetProductionPlanQuery } from 'pages/planning/production-plans/production-plans-endpoints/productionPlan.endpoints'
import { useGetUsersQuery, useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import * as yup from 'yup'
import {
  useGetMaintenanceOrderByIdQuery,
  usePostMaintenanceOrderMutation,
  usePutMaintenanceOrderMutation,
} from '../maintenance-order-endpoints/order.endpoints'
import { toast } from 'libs/toast'
import { useEffect } from 'react'

let priority = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
]
const filters = {
  page_no: 1,
  page_size: 10,
  searchText: '',
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const AddEditMaintenanceOrder = () => {
  const plantId = useSelector(selectSelectedItem)
  const { data: userInfo } = useUserInfoQuery({})
  const params = useParams()
  // from create maintenance order through maintenance request
  const paramsArray = params?.maintenanceRequestID ? params?.maintenanceRequestID.split(',') : []

  const navigate = useNavigate()
  const paramsId = false
  const [addMaintenanceOrder, { isLoading: isaddOrderLoading }] = usePostMaintenanceOrderMutation()
  const [updateMaintenanceOrder, { isLoading: isUpdateOrderLoading }] = usePutMaintenanceOrderMutation()

  const { data: maintenanceOrder, isLoading: isMaintenanceOrderLoading } = useGetMaintenanceOrderByIdQuery(
    {
      plantId: plantId?.id,
      moID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  // assignees
  const { data: users } = useGetUsersQuery(
    {
      org_id: userInfo?.organization?._id,
      filters: filters,
    },
    {
      skip: !userInfo?.organization?._id,
    },
  )
  const assignessOptions = users?.data?.map((user: any) => {
    return {
      label: `${user?.first_name} ${user?.last_name}`, // Correctly concatenate the names
      value: user?._id,
    }
  })

  const { data: plans } = useGetProductionPlanQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const plansOptions: any = plans?.data?.map((plan) => {
    return {
      label: plan?.planID,
      value: plan?._id,
    }
  })

  const { data: maintenanceRequest } = useGetMaintenanceRequestsQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const maintenanceRequestOptions = [
    ...(maintenanceRequest?.data
      ?.filter((item) => {
        if (item?.is_order === true) return false
        if (item?.status === 'REJECTED') return false
        return true
      })
      .map((request) => {
        return {
          label: request?.requestID,
          value: request?._id,
        }
      }) || []),

    ...(maintenanceOrder?.maintenance_requests?.map((request: any) => {
      return {
        label: request?.requestID,
        value: request?._id,
      }
    }) || []),
  ]


  const maintenanceOrderFormik = useFormik({
    initialValues: {
      task_name: '',
      description: '',
      priority: '',
      reason: '',
      orderID: '',
      impacted_production_plans: [],
      maintenance_requests: paramsArray ? paramsArray : [],
      assignees: [],
      deadline: null,
      machine: '',
    },
    validationSchema: yup.object().shape({
      task_name: yup.string().required('Task Name is required'),
      description: yup.string().required('Description is required'),
      priority: yup.string().required('Priority Level is required'),
      reason: yup.string().required('Reason is required'),
      orderID: yup.string().required('Order ID is required'),
      assignees: yup.array().min(1, 'Assignee  is required'),
      deadline: yup.string().required('Deadline is required'),
      maintenance_requests: yup.array().min(1, 'Maintenance Request is required'),

      machine: yup.string().required('Machine is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        task_name: values?.task_name,
        description: values?.description,
        priority_level: values?.priority,
        reason: values?.reason,
        orderID: values?.orderID,
        impacted_production_plans: values?.impacted_production_plans,
        maintenance_requests: values?.maintenance_requests,
        assignees: values?.assignees,
        deadline: values?.deadline,
        machine: values?.machine,
      }
      if (params?.id) {
        updateMaintenanceOrder({ data: payload, plantId: plantId?.id, moID: params?.id })
          .unwrap()
          .then(() => {
            toast.success('The Order ' + `${payload.orderID}` + 'is updated successfully')
            navigate('/maintenance/maintenance-order')
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Something went wrong while creating the maintenance order')
          })
      } else {
        addMaintenanceOrder({ data: payload, plantId: plantId?.id })
          .unwrap()
          .then(() => {
            toast.success('The Order ' + `${payload.orderID}` + 'is added successfully')
            navigate('/maintenance/maintenance-order')
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
            toast.error(errorMessage ?? 'Something went wrong while creating the maintenance order')
          })
      }
    },
  })
  useEffect(() => {
    if (maintenanceOrder) {
      maintenanceOrderFormik.setValues({
        task_name: maintenanceOrder?.task_name,
        description: maintenanceOrder?.description,
        priority: maintenanceOrder?.priority_level,
        reason: maintenanceOrder?.reason,
        orderID: maintenanceOrder?.orderID,
        impacted_production_plans: maintenanceOrder?.productionPlans?.map((item: any) => item?._id),
        maintenance_requests: maintenanceOrder?.maintenance_requests?.map((item: any) => item?._id),
        assignees: maintenanceOrder?.assignees?.map((item: any) => item?._id),
        deadline: maintenanceOrder?.deadline,
        machine: maintenanceOrder?.machine,
      })
    }
  }, [maintenanceOrder])

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{params?.id ? 'Edit Maintenance Order' : 'Create Maintenance Order'}</h1>
          </div>
        </div>
        {isMaintenanceOrderLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <span>
              <h5 className="h5">Maintenance Order Details</h5>
              <p className="p-sm-regular-1">
                {paramsId
                  ? 'Edit the existing Sales Order details to make necessary changes and updates.'
                  : 'Add details to initiate a new maintenance task.'}
              </p>
            </span>
            <div className=" flex flex-col gap-6">
              {/* --- process name and department */}
              <div className="grid  grid-cols-2 gap-x-4 gap-y-2 sm:w-full lg:w-10/12">
                <Field
                  type="textarea"
                  required={true}
                  label="Task Name"
                  formik={maintenanceOrderFormik}
                  name="task_name"
                  placeholder="Enter Task Name"
                />
                <Field
                  type="textarea"
                  required={true}
                  label="Description"
                  formik={maintenanceOrderFormik}
                  name="description"
                  placeholder="Enter description"
                />
                <Field
                  type="textarea"
                  required={true}
                  label="Reason"
                  formik={maintenanceOrderFormik}
                  name="reason"
                  placeholder="Enter reason"
                />
              </div>
              {/* ---- border ----- */}
              <hr></hr>
              {/* ---- border ----- */}
              <div className="flex w-10/12 gap-4 ">
                <div className="w-2/3">
                  <Field
                    type="text"
                    required={true}
                    label="Order ID"
                    formik={maintenanceOrderFormik}
                    name="orderID"
                    placeholder="Enter Order ID"
                  />
                  <Field
                    type="multiselect"
                    label="Impacted Production Plans"
                    formik={maintenanceOrderFormik}
                    name="impacted_production_plans"
                    options={plansOptions}
                    placeholder="Select impacted production plans"
                  />
                  <Field
                    type="date"
                    required={true}
                    label="Deadline"
                    formik={maintenanceOrderFormik}
                    name="deadline"
                    shouldDisablePastDate
                    placeholder="Select deadline"
                  />
                  <Field
                    type="text"
                    required={true}
                    label="Machines"
                    formik={maintenanceOrderFormik}
                    name="machine"
                    placeholder="Enter machines"
                  />
                </div>
                <div className="w-2/3">
                  <Field
                    type="select"
                    required={true}
                    label="Priority Level"
                    formik={maintenanceOrderFormik}
                    options={priority}
                    name="priority"
                    defaultValue={priority?.find(
                      (option) => option?.value === maintenanceOrderFormik?.values?.priority,
                    )}
                    placeholder="Select priority level"
                  />
                  <Field
                    type="multiselect"
                    label="Maintenance Requests"
                    required={true}
                    formik={maintenanceOrderFormik}
                    options={maintenanceRequestOptions}
                    name="maintenance_requests"
                    placeholder="Select requests"
                  />
                  <Field
                    type="multiselect"
                    label="Assignees"
                    required={true}
                    formik={maintenanceOrderFormik}
                    options={assignessOptions}
                    name="assignees"
                    placeholder="Select assignees"
                  />
                </div>
              </div>

              <div className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  color="success"
                  loading={isaddOrderLoading || isUpdateOrderLoading}
                  onClick={() => maintenanceOrderFormik.handleSubmit()}
                >
                  {params?.id ? 'Save' : 'Create'}
                </Button>
              </div>
            </div>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}
export default AddEditMaintenanceOrder
