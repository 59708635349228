import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useNavigate } from 'react-router-dom'
import { formatUnixDate, formatUnixTimeWithoutSecond } from 'utils/unixConverter'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'

import FilterPanel from './panel/FilterPanel'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import { NumberFormat } from 'utils/NumberFormat'
import Popup from 'libs/popup/Popup'
import ApproveByPopUp from './popup/ApproveByPopup'
import DeletePopUp from './popup/DeletePopup'
import { useGetTimesheetQuery } from './timesheet-endpoints/timesheet.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import RejectTimesheetPopup from './popup/RejectedPopup'
import Chip from 'libs/chip/Chip'
import { calculateDuration } from 'pages/shift-management/ShiftPolicyPage'
const additionalFilters = {
  dateBetween: [],
  shifts: [],
  designations: [],
  departments: [],
  status: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

interface State {
  state: boolean
  ids: string[]
}
interface DeleteState{
  state: boolean
  ids: string[]
  data: any
}

const TimeSheetPage = () => {
  useTabTitle('TimeSheets')
  const plant = useSelector(selectSelectedItem)

  const navigate = useNavigate()
  const [batchData, setBatchData] = useState<string[]>([])
  const [approveSelected, setApproveSelected] = useState<State>({
    state: false,
    ids: [],
  })
  const [deleteState, setDeleteState] = useState<DeleteState>({
    state: false,
    ids: [],
    data:''
  })

  const [isRejectPopup, setIsRejectPopup] = useState<State>({
    state: false,
    ids: [],
  })


  const { data: TimesheetData } = useGetTimesheetQuery(
    {
      plantId: plant?.id,
    },
    {
      skip: !plant?.id,
    },
  )

  const countNotVerifiedBy: any = TimesheetData?.data.filter((item: any) => item.status === 'PENDING').length

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      width: 150,
      Cell: ({ value }: any) => <div className="p-xs-regular">{formatUnixDate(value)}</div>,
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Employee Name</div>,
      accessor: 'employeeName',
      width: 150,
      Cell: ({ row }: any) => {
        return <div className="p-xs-regular">{row.original.employee?.employeeName ?? '-'}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Department</div>,
      accessor: 'department',
      width: 150,
      Cell: ({ row }: any) => {
        {
          return <div className="p-xs-regular">{row.original.department?.name ?? '-'}</div>
        }
      },
      disableSortBy: true,
    },

    {
      Header: <div className="flex w-full items-center justify-end">Designation</div>,
      accessor: 'designation',
      width: 150,
      Cell: ({ row }: any) => {
        {
          return <div className="p-xs-regular">{row.original?.designation?.name ?? '-'}</div>
        }
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Shift</div>,
      accessor: 'shift.shiftName',
      Cell: ({ value }: any) => {
        return <div className="p-xs-regular">{value || '-'}</div>
      },
    },

    {
      Header: <div className="flex w-full items-center justify-end">Start Time</div>,
      accessor: 'startTime',
      Cell: ({ value }: any) => {
        const startTime = value / 1000
        return <div className="p-xs-regular">{formatUnixTimeWithoutSecond(startTime)}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">End Time</div>,
      accessor: 'endTime',
      Cell: ({ value }: any) => {
        const endTime = value / 1000
        return <div className="p-xs-regular">{formatUnixTimeWithoutSecond(endTime)}</div>
      },
    },
    {
      Header: <div className="flex w-full items-center justify-end">Hours Worked</div>,
      accessor: 'hoursworked',
      Cell: ({ row }: any) => {
        return calculateDuration(row?.original?.startTime, row?.original?.endTime)
      },
      disableSortBy: true,
    },

    {
      Header: <div className="flex w-full items-center justify-end">Approval Status</div>,
      accessor: 'approvalStatus',
      Cell: ({ row }: any) => {
        const status = row?.original?.status ? row?.original?.status : '-'
        return (
          <div>
            <Chip title={status} />
          </div>
        )
      },
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      width: 100,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row gap-4">
            <button
              className={`${row?.original?.status === 'PENDING' ? '' : 'opacity-40'}`}
              onClick={
                row?.original?.status === 'PENDING'
                  ? (event: { stopPropagation: () => void }) => {
                      event.stopPropagation()
                      navigate(`/employee/time-sheet/${row?.original?._id}/edit-record`)
                    }
                  : undefined
              }
            >
              <Pen />
            </button>
            <button
              className={`${row?.original?.status === 'PENDING' ? '' : 'opacity-40'}`}
              onClick={
                row?.original?.status === 'PENDING'
                  ? (event: { stopPropagation: () => void }) => {
                      event.stopPropagation()
                      setDeleteState({ state: true, ids: row?.original?._id , data: row?.original })
                    }
                  : undefined
              }
            >
              <Bin />
            </button>
          </div>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddRecord = () => {
    navigate('/employee/time-sheet/add-record')
  }

  const toggleApprovePopup = (data: any) => {
    if (!approveSelected.state) {
      setApproveSelected({ state: true, ids: [data?._id] })
    } else {
      setApproveSelected({ state: false, ids: [] })
    }
  }

  const toggleDeletePopUp = (data: any) => {
    if (!deleteState.state) {
      setDeleteState({ state: true, ids: data?._id , data: '' })
    } else {
      setDeleteState({ state: false, ids: [], data: '' })
    }
  }
  const toggleRejectPopUp = (data: any) => {
    if (!isRejectPopup.state) {
      setIsRejectPopup({ state: true, ids: [data?._id] })
    } else {
      setIsRejectPopup({ state: false, ids: [] })
    }
  }

  // *************************** checkbox start ************************************ //
  const handleCheckboxClick = (data: any) => {
    const entryId = data?._id
    const isChecked = batchData.includes(entryId)
    if (isChecked) {
      setBatchData((prev) => {
        return prev.filter((id) => id !== entryId)
      })
    } else {
      setBatchData((prev) => [...prev, entryId])
    }
  }

  const handleSelectAllRows = (data: any) => {
    const entryIds = data
      ?.filter((entry: any) => entry?.original?.status === 'PENDING')
      .map((entry: any) => entry.original?._id)

    setBatchData(entryIds)
    if (entryIds.length === batchData.length) {
      setBatchData([])
    }
  }

  const handleEntrySelection = (row: any) => {
    if (row?.status === 'PENDING') {
      handleCheckboxClick(row) // Trigger checkbox click on row click
    }
  }

  // *************************** checkbox end ************************************ //
  return (
    <MotionDiv>
      <>
        <div>
          <div className="flex">
            <div className="flex items-center justify-between ">
              <h3 className="max-w-[500px] truncate pr-2 h5">Timesheets</h3>

              {countNotVerifiedBy > 0 ? (
                <div className=" w-[9rem] rounded-[28px] bg-[#F8D7DA] px-2 py-1 text-xs text-[#8E0000] ">
                  {NumberFormat(countNotVerifiedBy)} Pending approval
                </div>
              ) : (
                <div className=" w-[10rem] rounded-[28px] bg-[#D1E7DD] px-2 py-1 text-xs text-[#015314] ">
                  0 Pending verifications
                </div>
              )}
            </div>
          </div>
        </div>
        <p className="mt-2 p-sm-regular-1">
          This page displays Operator’s entries. Approve or reject entries to ensure accurate time tracking.
        </p>
        <FilterableSearchTable
          useQuery={useGetTimesheetQuery}
          columns={columns}
          addButton={{ label: 'Add Record', onClick: handleAddRecord }}
          placeholders={{
            emptyMessage: 'No timesheet records are added. Please add Record.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by Shift and Status',
          }}
          filterPanelComponent={FilterPanel}
          additionalFilters={additionalFilters}
          showCheckbox={true}
          batchData={batchData}
          onSelectAll={handleSelectAllRows}
          onSingleClick={handleEntrySelection}
        />
        <div>
          {batchData.length > 0 && (
            <div className="fixed bottom-0 left-[92px]  flex h-16 w-full items-center justify-between gap-4 bg-white">
              <div className="flex gap-2">
                <div className="mt-1 pl-8 p-sm-regular">
                  {NumberFormat(batchData?.length)} {t('selected')}
                </div>

                <Button
                  onClick={toggleApprovePopup}
                  className={`shadow-sm p-xs-regular btn-primary ${batchData?.length > 0 ? '' : 'cursor-default'}`}
                  disabled={batchData?.length === 0}
                >
                  {t('approve_selected')}
                </Button>

                <Button
                  onClick={toggleDeletePopUp}
                  className={`shadow-sm p-xs-regular btn-cancel ${batchData?.length > 0 ? '' : 'opacity-70'}`}
                  disabled={batchData?.length === 0}
                  startIcon={<Bin />}
                >
                  {t('delete_selected')}
                </Button>
              </div>
              <div className="pr-28">
                <Button onClick={toggleRejectPopUp} color="error" disabled={batchData?.length === 0}>
                  Reject Selected
                </Button>
              </div>
            </div>
          )}
        </div>

        <Popup
          isOpen={approveSelected?.state}
          title="Approve Entry?"
          onClose={() => {
            setApproveSelected({ state: false, ids: [] })
          }}
        >
          <ApproveByPopUp data={batchData} closePopUp={toggleApprovePopup} setBatchData={setBatchData} />
        </Popup>
        <Popup
          isOpen={deleteState.state}
          title="Delete Entry Details"
          onClose={() => {
            setDeleteState({ state: false, ids: [],data: '' })
          }}
        >
          <DeletePopUp
            closePopUp={toggleDeletePopUp}
            deleteState={deleteState}
            batchData={batchData}
            setBatchData={setBatchData}
            setDeleteState={setDeleteState}
          />
        </Popup>

        <Popup
          isOpen={isRejectPopup.state}
          title="Reject Entry?"
          onClose={() => {
            setIsRejectPopup({ state: false, ids: [] })
          }}
        >
          <RejectTimesheetPopup
            closePopUp={toggleRejectPopUp}
            isRejectPopup={isRejectPopup}
            batchData={batchData}
            setBatchData={setBatchData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default TimeSheetPage
